.list-item-wrapper {
  display: flex;
  padding: 10px;
  border: 1px solid silver;
  border-radius: 2px;
  margin: 10px 0 10px 0;
  flex-direction: row;
  justify-content: space-between;
}

.list-item-wrapper a {
  color: #e74c3c;
}

.list-item-wrapper a:hover {
  color: #c0392b;
  text-decoration: none;
}
