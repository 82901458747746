.input_item_city_a_sphera {
  background-color: #fffaf0;
  padding: 5px 10px 5px 10px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
}
.input_item_city_a_sphera_loader {
  text-align: right;
  width: 20px;
  margin-left: 10px;
}
.input_item_city_a_view {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.input_item_city_a_icon {
  padding-top: 2px;
  width: 25px;
  height: 20px;
  text-align: center;
}

.input_item_city_preview {
  background-color: #fffaf0;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
}
.input_item_city_preview span {
  text-align: right;
}
.input_item_city_preview_title {
  text-align: right;
  border-bottom: 1px solid #dcdcdc;
  padding: 5px 10px 5px 10px;
}
.input_item_city_a_text {
  margin-top: 3px;
  padding-top: 5px;
  padding-left: 5px;
}

.input_item_city_a_view_selected {
  padding: 5px 10px 10px 10px;
}
