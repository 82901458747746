.form-button-view {
  height: 35px;
  justify-content: center;
  border-radius: 4px;
  text-align: center;
}

.form-button-a {
  text-decoration: none;
  display: flex;
}
.form-button-center-progress {
  text-align: center;
  margin: 6px auto;
  padding: 0;
}
.form-button-center-text {
  text-align: center;
  font-size: 14px;
  margin: 7px auto;
}
.form-button-blue {
  background-color: #0984e3;
}

.form-button-blue-color {
  color: #ffffff;
}

.form-button-silver {
  background-color: #bdc3c7;
  border: 1px solid #abb0b4;
}

.form-button-silver-color {
  color: #2c3e50;
}

.form-button-remove {
  background-color: #e74c3c;
  border: 1px solid #ce4435;
}
.form-button-remove-color {
  color: #ffffff;
}
.form-button-underline {
  background-color: underline;
}

.form-button-underline-color {
  color: #57606f;
}

.form-button-flatLine {
  color: #3498db;
  border: 1px solid #3498db;
}
