.header_history_map_wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-left: 40px;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #dce0e6;
}

.header_history_map_wrapper .header_history_map_wrapper_link {
  padding: 0 5px 0 0;
  margin: 0 5px 0 0;
}

.header_history_map_wrapper_view {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 5px 0 0;
}

.header_history_map_wrapper a {
  text-decoration: none;
  font-size: 16px;
  color: #828282;
}

.header_history_map_wrapper a:hover {
  color: #636262;
  border-bottom: 3px solid #828282;
}
