.organization_content {
  margin: auto;
  width: 90%;
  min-width: 300px;
}
.organization_wrapper {
  position: relative;
  display: inline-block;
  margin: 20px auto;
  margin-bottom: 40px;
  width: 100%;
  min-width: 300px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fffaf0;
  border: 1px solid #e6e6fa;
  border-radius: 6px;
}

.organization_wrapper a {
  font-size: 18px;
  color: #3498db;
}

.organization_wrapper a:hover {
  color: #2980b9;
}

.organization_required {
  position: relative;
  width: 44%;
  margin-top: 10px;
  float: left;
  margin-left: 0.5%;
  margin-right: 0.5%;
  text-align: left;
  padding: 2%;
  min-height: 250px;
  margin-bottom: 10px;
}
.organization_required .borderTitle {
  font-size: 16px;
  padding-top: 10px;
}
.emptyOrganizationRequired {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.8;
  border-radius: 10px;
  z-index: 0;
}
.emptyOrganizationRequiredText {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  z-index: 1;
}
.organization_required a {
  margin-top: 10px;
}
.organization_wrapper_title {
  font-size: 22px;
  color: #4f4f4f;
  margin-bottom: 10px;
}
.organization_connect_message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 10px 0 0 0;
}
.organization_connect_message_view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 700px;
  min-width: 400px;
  border-width: 1px;
  border-color: #eb4d4b;
  border-style: solid;
  border-radius: 4px;
  background-color: #ff7979;
  padding: 10px;
}

.organization_connect_message_view .body {
  color: #ffffff;
  padding-right: 5px;
}

.organization_connect_message_view .connect_btn a {
  background-color: #ffffff;
  color: #ff7979;
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  text-decoration: none;
}
