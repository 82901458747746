.poster_modal_view_margin_top {
  margin-top: 15px;
}

.poster_modal_view_time_to_date_buttons {
  width: 200px;
  margin: 20px auto;
}

.poster_modal_view_time_to_date_null {
  color: silver;
  text-align: center;
}

.poster_view_images {
  position: relative;
  margin: auto;
  width: 250px;
  text-align: center;
}

.poster_view_images .poster_view_images_remove {
  position: absolute;
  background-color: white;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  top: 15px;
  left: 15px;
}

.poster_img {
  width: 100%;
  height: 100%;
  border-radius: 10%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 25px;
  object-fit: cover;
}
