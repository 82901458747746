.btn-small {
  font-size: 1rem;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  margin: 0 2px 0 2px;
}

.btn-small-default {
  color: #38393a;
  border: 1px solid #606160;
  background-color: #ffffff;
}

.btn-small-primary {
  color: white;
  border: 1px solid #51af33;
  background-color: #51af33;
}

.btn-small:active,
.btn-small:focus {
  outline: none !important;
  outline-style: none;
  outline-width: 0px !important;
  outline-color: none !important;
}

.btn-small:active {
  opacity: 0.4;
}
