.street_header_sub_menu_view {
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 95%;
  right: 0px;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-right: 10px;
  text-align: right;
}

.street_alert_sub_menu_view {
  margin-bottom: 10px;
}

.street_wrapper_view {
  padding: 10px;
  padding-bottom: 200px;
}

.street_wrapper_view_list_item {
  display: table;
  width: 100%;
  border-bottom: 1px solid #9c9c9c;
}
.street_wrapper_view_list_item .street_wrapper_view_list_item_cell {
  display: table-cell;
}

.street_wrapper_view_list_item
  .street_wrapper_view_list_item_cell_title_street {
  width: 40%;
  font-size: 18px;
  padding-top: 8px;
  padding-bottom: 10px;
}

.street_wrapper_view_list_item .street_wrapper_view_list_item_cell_title_city {
  width: 40%;
  font-size: 18px;
  padding-top: 8px;
  padding-bottom: 10px;
}

.street_wrapper_view_list_item
  .street_wrapper_view_list_item_cell_title_editor {
  width: 10%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.street_wrapper_view_list_item
  .street_wrapper_view_list_item_cell_title_remove {
  width: 10%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.removeStreet {
  font-weight: bold;
}
.street_wrapper_view_next_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px auto;
}
