.header_sub_menu {
  display: table;
  border-bottom: 1px solid #dcdcdc;
  text-align: right;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f8f8ff;
}
.header_sub_menu_btn {
  overflow: hidden;
  margin-right: 5px;
}

.header_sub_menu_btn {
  display: table-cell;
}

.header_sub_menu_title {
  /*width: 100%;*/
  font-size: 26px;
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 0px;
  color: #4f4f4f;
  text-align: left;
}
.header_sub_menu_title_is_logotype {
  /*width: 100%;*/
  font-size: 26px;
  padding-top: 20px;
  padding-left: 10px;
  padding-bottom: 0px;
  color: #4f4f4f;
  text-align: left;
}
.header_sub_menu_sub_title {
  /*width: 100%;*/
  font-size: 16px;
  padding-left: 40px;
  padding-bottom: 0px;
  color: #4f4f4f;
  text-align: left;
}

.header_sub_menu_sub_title_is_logotype {
  /*width: 100%;*/
  font-size: 16px;
  padding-left: 10px;
  padding-bottom: 0px;
  color: #4f4f4f;
  text-align: left;
}
.header_sub_menu_btn {
}

.header_sub_menu_btn_view {
  justify-content: flex-end;
  justify-items: flex-end;
  padding-right: 10px;
}

.header_sub_menu_children {
  padding-left: 40px;
  border-bottom: 1px solid #dcdcdc;
}

.header_sub_menu_no_children {
  padding-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
}

.header_sub_menu_left_logotype {
  display: flex;
}

.header_sub_menu_left_logotype_view {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid #dcdcdc;
  background-color: white;
  margin-top: 30px;
  object-fit: cover;
  margin-left: 40px;
}
