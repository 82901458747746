.menu {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  background: white;
  text-align: right;
  z-index: 9;
}

.menu-color-green {
  color: #27ae60;
}
.menu-li-div-sub-menu:before {
  content: " ";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #dcdcdc;
  position: absolute;
  width: 0;
  height: 0;
  top: -10px;
  left: 45%;
  right: 45%;
}
.menu-li-div-sub-menu:after {
  content: " ";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  width: 0;
  height: 0;
  top: -8px;
  left: 45%;
  right: 45%;
}
.menu-li-div-sub-menu {
  position: absolute;
  list-style: none;
  padding: 0;
  top: 60px;
  min-width: 150px;
  border: 1px solid #dcdcdc;
  background-color: #fff;
  box-shadow: -1px 11px 20px -6px rgba(0, 0, 0, 0.75);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 999999;
}
.menu li .menu-li-div-sub-menu li {
  display: block;
  line-height: 40px;
  text-align: left;
}
.menu li .menu-li-div-sub-menu li a {
  display: block;
  text-decoration: none;
}
.menu li .menu-li-div-sub-menu li a:hover {
  background-color: #eee9e9;
}

.menu li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  z-index: 99999;
}
.menu li:after {
  content: "";
  height: 60px;
  width: 10px;
}
.menu-li-div-text {
  display: flex;
  height: 60px;
  align-items: center;
  background-color: black;
}

.menu-li-div-badges {
  display: flex;
  padding-top: 7px;
  align-items: center;
}

.menu-li-div-avatar {
  display: flex;
  justify-content: center;
  align-content: center;
  /* padding-top: 9px;
  padding-top: 9px; */
}
.menu-li-div-text-icon-left-svg {
  vertical-align: middle;
  margin-right: 5px;
}

.menu li a,
.menu li .link-button {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  font-family: "Source Sans Pro Regular";
  color: #3498db;
  font-size: 1.2em;
  background-color: transparent;
  padding-left: 20px;
  padding-right: 20px;
}

ul li a:hover,
ul li .link-button:hover {
  text-decoration: underline;
  opacity: 0.7;
}

.menu-executor {
  /* position: relative; */
}

.menu-executor .link-button {
  cursor: pointer;
}

/* .menu-enter {
  background-color: #fff;
  outline: none;
  text-decoration: none;
  margin: 0;
  z-index: 99;
}

.menu-executor-enter {
  position: absolute;
  right: 4%;
  margin-top: -1px;
  padding: 0px;
  width: 200px;
  background-color: #fff;
  border-radius: 10px;
  border-top-right-radius: 0px;
  box-shadow: -1px 11px 20px -6px rgba(0, 0, 0, 0.75);
  z-index: 9999;
}

.menu-executor-enter a {
  padding: 5px 10px 5px 25px;
  margin: 0;
  text-align: left;
}

.menu-executor-enter a:hover {
  padding: 5px 10px 5px 25px;
  color: silver;
}

.menu-executor-enter-item-title {
  color: #9c9c9c;
  font-size: 1.1em;
  padding: 10px 10px 5px 15px;
  text-align: left;
}

.background_menu {
  position: fixed;
  background-color: silver;
  opacity: 0.4;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
} */
