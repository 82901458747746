.info-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.info-content-view-warning {
  background-color: bisque;
  padding: 10px 15px 10px 15px;
  border-radius: 10px;
  color: rgb(156, 100, 32);
}
