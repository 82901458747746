.alert_background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.4;
  z-index: 999999991;
}
.alert_background_alert {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  z-index: 999999992;
}
.alert_background_alert:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.alert_modal {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-color: white;
  max-width: 500px;
  min-width: 200px;
  border-radius: 4px;
  box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.alert_modal_title {
  padding: 3px 10px 3px 10px;
  color: #fff;
  font-size: 18px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid #bdc3c7;
  text-align: left;
}

.color_primary {
  background: linear-gradient(#0984e3, rgb(22, 104, 227)) rgb(70, 72, 197);
}

.color_secondary {
  background-color: #b2bec3;
}

.color_success {
  background-color: #51af33;
  background: linear-gradient(#51af33, rgb(39, 214, 92)) rgb(106, 197, 70);
}

.color_danger {
  background-color: #d63031;
}

.color_warning {
  background-color: #fdcb6e;
}
.color_info {
  background-color: #74b9ff;
}

.alert_modal_body {
  background-color: #fff;

  overflow: auto;
  text-align: left;
  padding: 15px;
  margin-bottom: 45px;
}

.alert_modal_btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ecf0f1;
  border-top: 1px solid #bdc3c7;
  padding: 8px 8px 8px 8px;
  text-align: right;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.alert_modal_btn .btn {
  max-width: 100px;
  font-size: 1rem;
  padding: 4px 2px 4px 2px;
  border-radius: 4px;
  margin: 0 2px 0 2px;
}

.alert_modal_btn .btnNoCancel {
  border: 1px solid #b2bec3;
}

.alert_modal_btn .btn_cancel {
  color: #636e72;
}

.alert_modal_btn .btn:active,
.alert_modal_btn .btn:focus {
  outline: none !important;
  outline-style: none;
  outline-width: 0px !important;
  outline-color: none !important;
}

.alert_background_alert_progress {
  position: absolute;
  left: 0;
  top: 35px;
  right: 0;
  bottom: 0;
  padding-top: 10px;
  background-color: white;
  justify-content: center;
  opacity: 0.4;
  display: inline-block;
  z-index: 5;
}

.alert_background_alert_progress_confirm {
  display: flex;
  position: absolute;
  left: 0;
  top: 35px;
  right: 0;
  padding-top: 10px;
  background-color: white;
  border-bottom: 1px solid silver;
  padding-bottom: 10px;
  justify-content: center;
  display: inline-block;
  -webkit-box-shadow: 0px 7px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 7px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 7px 8px 0px rgba(34, 60, 80, 0.2);
  z-index: 7;
}

.alert_background_alert_progress_confirm .text {
  font-size: 14px;
  padding: 10px;
  color: #000;
}

.alert_background_alert_progress_confirm .btn {
  display: flex;
  flex: 1;
  margin-top: 10px;
  flex-direction: row;
  border: 0px solid black;
  justify-content: center;
  align-items: center;
}

.alert_modal_header {
  border-bottom: 1px solid silver;
  padding: 0 10px 0 10px;
  background-color: #f5f5f5;
}

.alert_overlay_next_ {
  opacity: 0.8;
  z-index: 999999993;
}

.alert_overlay_main_next_ {
  z-index: 999999994;
}
