.star-wrapper {
  width: 200px;
}
.star-wrapper-view {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.star-wrapper-view-text {
  margin-left: 10px;
  font-size: 16px;
}
