.wrapper-tabs {
  padding-top: 20px;
  padding-bottom: 12px;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: pre;
}

.wrapper-tabs::-webkit-scrollbar {
  width: 4px;
  background: inherit;
}

.wrapper-tabs::-webkit-scrollbar-thumb {
  border-width: 1px 1px 1px 2px;
  border-color: #777;
  background-color: #aaa;
}

.wrapper-tabs::-webkit-scrollbar-thumb:hover {
  border-width: 1px 1px 1px 2px;
  border-color: #555;
  background-color: #777;
}

.wrapper-tabs::-webkit-scrollbar-track {
  border-width: 0;
}
.wrapper-tabs::-webkit-scrollbar-track:hover {
  border-left: solid 1px #aaa;
  background-color: #eee;
}
/*.wrapper-tabs::-webkit-scrollbar {
  width: 0;
  height: 0;
} */

.wrapper-tabs-view {
  padding-left: 0px;
  text-align: left;
}

.wrapper-tabs-view a {
  font-size: 16px;
  padding-left: 0px;
  padding-right: 0px;
  text-decoration: none;
  margin-right: 15px;
}
.wrapper-tabs-view a:hover {
  color: #2980b9;
}

.wrapper-tabs-view-success {
  color: #3498db;
}

.wrapper-tabs-view-error {
  color: #e74c3c;
}

.wrapper-tabs-view-a {
}

.wrapper-tabs-view-a-select {
  text-decoration: underline;
  padding-bottom: 10px;
  border-bottom: 5px solid #3498db;
}
.wrapper-tabs-view-a-select-error {
  text-decoration: underline;
  padding-bottom: 10px;
  border-bottom: 5px solid #e74c3c;
}
