.content_component {
  width: 100%;
  padding-top: 20px;
}

.content_center {
  margin: 0 auto;
}

.wrapper_default {
  padding: 10px;
  max-width: 900px;
  min-width: 300px;
}
