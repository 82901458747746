.new_organization_view {
  margin: 20px auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  max-width: 500px;
  min-width: 300px;
}

.new_organization_view_phone_null {
  text-align: center;
  margin-bottom: 20px;
}

.new_organization_view_phone_button {
  width: 150px;
  padding-top: 20px;
  margin-top: 20px;
  margin: auto;
}
/*
Table
*/
.new_organization_view_table {
  margin: 20px auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  max-width: 700px;
  min-width: 500px;
}

.form_operating_mode_table {
  display: table;
  width: 100%;
}

.form_operating_mode_table_cell {
  display: table-cell;
  padding-top: 10px;
  padding-bottom: 10px;
}

.new_organization_view_table .header {
  background-color: #fffaf0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid #dcdcdc;
  text-align: center;
}

.form_operating_mode_table .day {
  width: 30%;
  font-size: 16px;
  padding-left: 5px;
  padding-right: 5px;
}

.form_operating_mode_table .timeParametr {
  width: 30%;
  font-size: 16px;
  padding-left: 5px;
  padding-right: 5px;
}

.form_operating_mode_table .border_left {
  border-left: 1px solid #dcdcdc;
}

.form_operating_mode_table .border_right {
  border-right: 1px solid #dcdcdc;
}
.form_operating_mode_table .border_bottom {
  border-bottom: 1px solid #dcdcdc;
}

.form_operating_mode_table .timeDay {
  width: 20%;
  padding-left: 5px;
  padding-right: 5px;
}

.form_operating_mode_table_cell .work_day {
  font-size: 12px;
}

.new_organization_view_table .vih {
  background-color: #fff5ee;
}

.files_hidden {
  display: none;
}
.new_organization_view_all_images {
  margin-top: 20px;
  width: 100%;
}
.new_organization_view_all_images_count_text {
  margin-top: 20px;
}
.new_organization_view_images {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 5px 0 5px;
  width: 150px;
  height: 150px;
  text-align: center;
  float: left;
}
.new_organization_view_images .new_organization_view_images_remove {
  position: absolute;
  background-color: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  top: 5px;
  left: 20px;
}
.dostpr_img {
  width: 80%;
  height: 80%;
  border-radius: 10%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 25px;
  object-fit: cover;
}

.new_organization_view_images_vertical {
  position: relative;
  margin: 5px 5px 0 5px;
  width: 250px;
  height: 350px;
  text-align: center;
  float: left;
}

.dostpr_img_vertical {
  width: 80%;
  height: 80%;
  border-radius: 10%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 25px;
  object-fit: cover;
}
.new_organization_view_images_title {
  position: absolute;
  bottom: 25px;
  left: 0;
  border: 1px solid black;
  z-index: 5;
}

.dostpr_img_logotype {
  border: 2px solid #f39c12;
}
.dostpr_img_no_logotype {
  border: 2px solid white;
}

.new_organization_view_images_logo {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0px;
}
.new_organization_view_images_logo a {
  text-decoration: none;
  color: #3498db;
}

.new_organization_buttons {
  width: 200px;
  margin: auto;
  text-align: center;
}

.new-organization-phone-add {
  background-color: antiquewhite;
  border: 1px solid silver;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}
