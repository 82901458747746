.main_preview {
  margin-top: 20px;
  margin-bottom: 100px;
}
.news_modal_view_margin_top {
  margin-top: 10px;
  text-align: right;
}
.news_modal_view_margin_top_view {
  display: inline-block;
}

.news_modal_preview_video {
  position: relative;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow: hidden;
  margin-bottom: 20px;
}
.news_modal_preview_video_size {
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 10px;
}

.news_modal_preview_video_close {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 9999;
}
.news_line {
  margin-top: 20px;
}
