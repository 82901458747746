.wrapper-main {
}

.wrapper-main-page {
  width: 400px;
  margin: 10px auto;
}

.wrapper-main-page h1 {
  text-align: center;
}

.main_preview {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 100px;
}
.main_preview .organization_title {
  display: flex;
  width: 100%;
}
.main_preview_null_organizations {
  text-align: center;
  margin-top: 30px;
}
.main_preview .organization {
  display: flex;
  overflow: hidden;
  padding: 5px;
  margin: 5px;
  width: 200px;
  padding: 10px;
  height: 100px;
  border: 0.5px solid rgba(148, 142, 148, 0.5);
  border-radius: 8px;
  cursor: default;
}

.main_preview .organization:hover {
  border: 0.5px solid transparent;
  border-radius: 6px;

  -webkit-box-shadow: 0px 2px 14px -3px rgba(148, 142, 148, 1);
  -moz-box-shadow: 0px 2px 14px -3px rgba(148, 142, 148, 1);
  box-shadow: 0px 2px 14px -3px rgba(148, 142, 148, 1);
}
.main_preview .organization_cell {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.main_preview .organization_body {
  /* padding: 5px 5px 5px 5px;
  border: 1px solid transparent; */
}

.organization_body:hover {
  cursor: pointer;
}
.organization_body .title {
  font-size: 14px;
  font-weight: bold;
  color: #363636;
}
.organization_body .address {
  font-size: 12px;
  font-weight: normal;
  color: #828282;
}

.organization_body .active {
  display: flex;
  font-size: 12px;
}

.main_preview .organization_setting {
  padding: 10px;
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_preview .organization_setting {
}
.organization_cell .a0 {
  color: #1abc9c;
}
.organization_cell .a1 {
  color: #2ecc71;
}
.organization_cell .a2 {
  color: #e74c3c;
}

.organization_cell_left {
  display: flex;
  flex: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-flow: row nowrap;
}

.organization_cell_left_logotype {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #dcdcdc;
  background-color: white;
  margin-right: 10px;
  margin-top: 5px;
  object-fit: cover;
}

.main_alert_no_user_title {
  font-size: 16px;
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}
