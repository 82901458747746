.input_item_city {
  height: 250px;
  overflow: scroll;
}

.input_item_city_a {
  font-size: 16px;
  color: #3498db;
  text-decoration: none;
}
.input_item_city_a:hover {
  color: #2980b9;
}
