.select_items_content {
  display: inline-block;
  text-align: left;
}
.select_items_wrapper {
  border-radius: 8px;
  text-align: left;
  z-index: 999999994;
}

.select_items_wrapper_success {
  border: 1px solid #b1b9c3;
}
.select_items_wrapper_error {
  border: 1px solid #e74c3c;
}
.select_items_wrapper_view {
  display: flex;
}

.select_items_wrapper_a {
  text-decoration: none;
  color: #4f4f4f;
}

.select_items_wrapper .select_items_wrapper_view .body {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  padding: 6px 5px 5px 10px;
}
.select_items_wrapper .select_items_wrapper_view .icon {
  width: 30px;
  padding: 7px 5px 0 5px;
}

.select_items_wrapper .select_sub_items_wrapper {
  position: absolute;
  margin-top: -3px;
  margin-left: -1px;
  overflow-x: auto;
  max-height: 200px;
  background-color: #fff;
  -webkit-box-shadow: 0px 5px 7px -1px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 0px 5px 7px -1px rgba(0, 0, 0, 0.38);
  box-shadow: 0px 5px 7px -1px rgba(0, 0, 0, 0.38);

  border: 1px solid #dcdcdc;
  z-index: 999999996;
}
.select_items_wrapper .select_sub_items_right {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.select_items_wrapper .select_sub_items_left {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.select_items_wrapper .select_sub_items_wrapper .selected {
  padding: 10px 5px 10px 5px;
  font-size: 16px;
}

.select_items_wrapper .select_sub_items_wrapper .top_border {
  border-top: 1px solid #dcdcdc;
}
.select_sub_items_wrapper_a {
  color: #696969;
  text-decoration: none;
}
.select_sub_items_wrapper_a:hover {
  color: #000000;
}

.selected:hover {
  background-color: #fffaf0;
}

/* Text */

.select_items_wrapper input {
  width: 100%;

  font-size: 16px;
  padding: 10px 0 10px 10px;
  border: 0;
  border-bottom: 1px solid silver;
  outline: none;
}

.select_items_wrapper .loader {
  margin: 10px 0 10px 0;
}

.select_items_wrapper .notSearching {
  margin: 5px auto;
  text-align: center;
  color: #b3b1b1;
}
.sub-title {
  font-size: 12px;
  color: #828282;
}

.select-items-clear-view {
  display: flex;
  justify-content: flex-end;
  padding: 5px 10px 5px 10px;
}
.select-items-clear-all {
  color: #2980b9;
  text-align: center;
}
.select-items-clear-all:hover {
  color: #194563;
}
