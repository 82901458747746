.preloader_all {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding-top: calc(25%);
  bottom: 0;
  text-align: center;
  opacity: 0.5;
  z-index: 9999;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #b5b5b5 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.menu {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  background: white;
  text-align: right;
  z-index: 9;
}

.menu-color-green {
  color: #27ae60;
}
.menu-li-div-sub-menu:before {
  content: " ";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #dcdcdc;
  position: absolute;
  width: 0;
  height: 0;
  top: -10px;
  left: 45%;
  right: 45%;
}
.menu-li-div-sub-menu:after {
  content: " ";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  width: 0;
  height: 0;
  top: -8px;
  left: 45%;
  right: 45%;
}
.menu-li-div-sub-menu {
  position: absolute;
  list-style: none;
  padding: 0;
  top: 60px;
  min-width: 150px;
  border: 1px solid #dcdcdc;
  background-color: #fff;
  box-shadow: -1px 11px 20px -6px rgba(0, 0, 0, 0.75);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 999999;
}
.menu li .menu-li-div-sub-menu li {
  display: block;
  line-height: 40px;
  text-align: left;
}
.menu li .menu-li-div-sub-menu li a {
  display: block;
  text-decoration: none;
}
.menu li .menu-li-div-sub-menu li a:hover {
  background-color: #eee9e9;
}

.menu li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  z-index: 99999;
}
.menu li:after {
  content: "";
  height: 60px;
  width: 10px;
}
.menu-li-div-text {
  display: flex;
  height: 60px;
  align-items: center;
  background-color: black;
}

.menu-li-div-badges {
  display: flex;
  padding-top: 7px;
  align-items: center;
}

.menu-li-div-avatar {
  display: flex;
  justify-content: center;
  align-content: center;
  /* padding-top: 9px;
  padding-top: 9px; */
}
.menu-li-div-text-icon-left-svg {
  vertical-align: middle;
  margin-right: 5px;
}

.menu li a,
.menu li .link-button {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  font-family: "Source Sans Pro Regular";
  color: #3498db;
  font-size: 1.2em;
  background-color: transparent;
  padding-left: 20px;
  padding-right: 20px;
}

ul li a:hover,
ul li .link-button:hover {
  text-decoration: underline;
  opacity: 0.7;
}

.menu-executor {
  /* position: relative; */
}

.menu-executor .link-button {
  cursor: pointer;
}

/* .menu-enter {
  background-color: #fff;
  outline: none;
  text-decoration: none;
  margin: 0;
  z-index: 99;
}

.menu-executor-enter {
  position: absolute;
  right: 4%;
  margin-top: -1px;
  padding: 0px;
  width: 200px;
  background-color: #fff;
  border-radius: 10px;
  border-top-right-radius: 0px;
  box-shadow: -1px 11px 20px -6px rgba(0, 0, 0, 0.75);
  z-index: 9999;
}

.menu-executor-enter a {
  padding: 5px 10px 5px 25px;
  margin: 0;
  text-align: left;
}

.menu-executor-enter a:hover {
  padding: 5px 10px 5px 25px;
  color: silver;
}

.menu-executor-enter-item-title {
  color: #9c9c9c;
  font-size: 1.1em;
  padding: 10px 10px 5px 15px;
  text-align: left;
}

.background_menu {
  position: fixed;
  background-color: silver;
  opacity: 0.4;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
} */

.header_history_map_wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-left: 40px;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #dce0e6;
}

.header_history_map_wrapper .header_history_map_wrapper_link {
  padding: 0 5px 0 0;
  margin: 0 5px 0 0;
}

.header_history_map_wrapper_view {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 5px 0 0;
}

.header_history_map_wrapper a {
  text-decoration: none;
  font-size: 16px;
  color: #828282;
}

.header_history_map_wrapper a:hover {
  color: #636262;
  border-bottom: 3px solid #828282;
}

.header_sub_menu {
  display: table;
  border-bottom: 1px solid #dcdcdc;
  text-align: right;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f8f8ff;
}
.header_sub_menu_btn {
  overflow: hidden;
  margin-right: 5px;
}

.header_sub_menu_btn {
  display: table-cell;
}

.header_sub_menu_title {
  /*width: 100%;*/
  font-size: 26px;
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 0px;
  color: #4f4f4f;
  text-align: left;
}
.header_sub_menu_title_is_logotype {
  /*width: 100%;*/
  font-size: 26px;
  padding-top: 20px;
  padding-left: 10px;
  padding-bottom: 0px;
  color: #4f4f4f;
  text-align: left;
}
.header_sub_menu_sub_title {
  /*width: 100%;*/
  font-size: 16px;
  padding-left: 40px;
  padding-bottom: 0px;
  color: #4f4f4f;
  text-align: left;
}

.header_sub_menu_sub_title_is_logotype {
  /*width: 100%;*/
  font-size: 16px;
  padding-left: 10px;
  padding-bottom: 0px;
  color: #4f4f4f;
  text-align: left;
}
.header_sub_menu_btn {
}

.header_sub_menu_btn_view {
  justify-content: flex-end;
  justify-items: flex-end;
  padding-right: 10px;
}

.header_sub_menu_children {
  padding-left: 40px;
  border-bottom: 1px solid #dcdcdc;
}

.header_sub_menu_no_children {
  padding-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
}

.header_sub_menu_left_logotype {
  display: flex;
}

.header_sub_menu_left_logotype_view {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid #dcdcdc;
  background-color: white;
  margin-top: 30px;
  object-fit: cover;
  margin-left: 40px;
}

.header_sub_menu_button {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 22.5px;
}
.header_sub_menu_button_color_default {
  background-color: #3498db;
}

.form-input {
  width: 100%;
  border-radius: 4px;
  padding: 5px 0 5px 0;
  /*border: 1px solid #b1b9c3;*/
  margin-top: 15px;
  background-color: white;
}

.form-input input {
  width: 90%;
  font-size: 16px;
  padding: 6px 0 6px 10px;
  border: 0;
  outline: none;
}

.form-input span {
  padding: 6px 0 6px 10px;
  color: rgb(133, 131, 131);
}

.success-input {
  border: 1px solid #b1b9c3;
}

.error-input {
  border: 1px solid red;
}

.error-text-input {
  color: red;
  text-align: left;
}
.date_picker_component_view {
  border-radius: 4px;
  border: 1px solid #b1b9c3;
}
.dateToTime_wrapper {
  padding: 10px 10px 0 10px;
  border: 1px solid silver;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #faebd7;
  z-index: 0;
}
.date_picker_component {
  width: 90%;
  font-size: 16px;
  padding: 6px 0 6px 10px;
  border: 0;
  outline: none;
}

.dateToTime_wrapper_datetime_view {
  position: relative;
}
.dateToTime_wrapper_datetime_remove {
  position: absolute;
  right: 10px;
  top: 0px;
  cursor: pointer;
}

.wrapper-loader {
  display: inline;
}
.sk-fading-circle {
  margin: 0 auto;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle-silver:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle-white:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.form-button-view {
  height: 35px;
  justify-content: center;
  border-radius: 4px;
  text-align: center;
}

.form-button-a {
  text-decoration: none;
  display: flex;
}
.form-button-center-progress {
  text-align: center;
  margin: 6px auto;
  padding: 0;
}
.form-button-center-text {
  text-align: center;
  font-size: 14px;
  margin: 7px auto;
}
.form-button-blue {
  background-color: #0984e3;
}

.form-button-blue-color {
  color: #ffffff;
}

.form-button-silver {
  background-color: #bdc3c7;
  border: 1px solid #abb0b4;
}

.form-button-silver-color {
  color: #2c3e50;
}

.form-button-remove {
  background-color: #e74c3c;
  border: 1px solid #ce4435;
}
.form-button-remove-color {
  color: #ffffff;
}
.form-button-underline {
  background-color: underline;
}

.form-button-underline-color {
  color: #57606f;
}

.form-button-flatLine {
  color: #3498db;
  border: 1px solid #3498db;
}

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */ /* Konqueror */ /* Firefox */ /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */ /* Konqueror */ /* Firefox */ /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.formInput {
  display: flex;
  align-items: center;
  padding-right: 0;
  flex-direction: row;
}
.formInput .rightIcon {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
}
.formInput input {
  /* width: 90%; */
  flex: 1 1;
  font-size: 16px;
  padding: 6px 0 6px 10px;
  border: 0px solid black;
  outline: none;
}
.formInput textarea {
  /* width: 90%; */
  flex: 1 1;
  font-size: 16px;
  padding: 6px 0 6px 10px;
  border: 0;
  outline: none;
}

.formInput_view {
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  padding-right: 5px;
}

.formInput_icon {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  opacity: 0.5;
}
.formInput_icon_remove {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
}
.formInput_input {
  width: 100%;
}

.formInputError {
  position: absolute;
  border: 0px solid black;
  font-size: 12px;
  width: 100%;
  bottom: -18px;
  left: 0;
  color: red;
}

.formInput_color {
  position: relative;
}

.formInput_color_code {
  position: absolute;
  right: 5px;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  top: 0;
}

.formInput_top_title {
  display: table;
  clear: both;
}

.formInput_color_modal_color {
  position: absolute;
  z-index: 5;
}

.formInput_color_modal_color_btn {
  display: flex;
  background-color: #fff;
  justify-content: space-around;
  border-width: 1px;
  border-style: solid;
  border-color: silver;
  margin-top: -5px;
  padding: 5px 5px 5px 5px;
}

.formInput_color_modal_color_btn a {
  text-decoration: none;
  color: #3498db;
}

.formInput_view_select {
  cursor: pointer;
}
.formInput_select {
  color: #3498db;
  font-size: 16px;
  text-decoration: underline;
  padding: 3px 5px 3px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.input_emoji {
  /* position: absolute; */
  margin-top: -10px;
  /* z-index: 45; */
}
.form-control-phone {
  font-size: 17px;
  margin-left: 10px;
}

.btn-big {
  width: 100%;
  max-width: 100%;
  font-size: 1.25rem;
  padding: 15px 5px 15px 5px;
  border-radius: 4px;
}

.btn-block {
}

.btn-big-primary {
  color: white;
  border: 1px solid #51af33;
  background-color: #51af33;
}

.btn-small {
  font-size: 1rem;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  margin: 0 2px 0 2px;
}

.btn-small-default {
  color: #38393a;
  border: 1px solid #606160;
  background-color: #ffffff;
}

.btn-small-primary {
  color: white;
  border: 1px solid #51af33;
  background-color: #51af33;
}

.btn-small:active,
.btn-small:focus {
  outline: none !important;
  outline-style: none;
  outline-width: 0px !important;
  outline-color: none !important;
}

.btn-small:active {
  opacity: 0.4;
}

.alert_background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.4;
  z-index: 999999991;
}
.alert_background_alert {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  z-index: 999999992;
}
.alert_background_alert:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.alert_modal {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-color: white;
  max-width: 500px;
  min-width: 200px;
  border-radius: 4px;
  box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.alert_modal_title {
  padding: 3px 10px 3px 10px;
  color: #fff;
  font-size: 18px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid #bdc3c7;
  text-align: left;
}

.color_primary {
  background: linear-gradient(#0984e3, rgb(22, 104, 227)) rgb(70, 72, 197);
}

.color_secondary {
  background-color: #b2bec3;
}

.color_success {
  background-color: #51af33;
  background: linear-gradient(#51af33, rgb(39, 214, 92)) rgb(106, 197, 70);
}

.color_danger {
  background-color: #d63031;
}

.color_warning {
  background-color: #fdcb6e;
}
.color_info {
  background-color: #74b9ff;
}

.alert_modal_body {
  background-color: #fff;

  overflow: auto;
  text-align: left;
  padding: 15px;
  margin-bottom: 45px;
}

.alert_modal_btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ecf0f1;
  border-top: 1px solid #bdc3c7;
  padding: 8px 8px 8px 8px;
  text-align: right;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.alert_modal_btn .btn {
  max-width: 100px;
  font-size: 1rem;
  padding: 4px 2px 4px 2px;
  border-radius: 4px;
  margin: 0 2px 0 2px;
}

.alert_modal_btn .btnNoCancel {
  border: 1px solid #b2bec3;
}

.alert_modal_btn .btn_cancel {
  color: #636e72;
}

.alert_modal_btn .btn:active,
.alert_modal_btn .btn:focus {
  outline: none !important;
  outline-style: none;
  outline-width: 0px !important;
  outline-color: none !important;
}

.alert_background_alert_progress {
  position: absolute;
  left: 0;
  top: 35px;
  right: 0;
  bottom: 0;
  padding-top: 10px;
  background-color: white;
  justify-content: center;
  opacity: 0.4;
  display: inline-block;
  z-index: 5;
}

.alert_background_alert_progress_confirm {
  display: flex;
  position: absolute;
  left: 0;
  top: 35px;
  right: 0;
  padding-top: 10px;
  background-color: white;
  border-bottom: 1px solid silver;
  padding-bottom: 10px;
  justify-content: center;
  display: inline-block;
  box-shadow: 0px 7px 8px 0px rgba(34, 60, 80, 0.2);
  z-index: 7;
}

.alert_background_alert_progress_confirm .text {
  font-size: 14px;
  padding: 10px;
  color: #000;
}

.alert_background_alert_progress_confirm .btn {
  display: flex;
  flex: 1 1;
  margin-top: 10px;
  flex-direction: row;
  border: 0px solid black;
  justify-content: center;
  align-items: center;
}

.alert_modal_header {
  border-bottom: 1px solid silver;
  padding: 0 10px 0 10px;
  background-color: #f5f5f5;
}

.alert_overlay_next_ {
  opacity: 0.8;
  z-index: 999999993;
}

.alert_overlay_main_next_ {
  z-index: 999999994;
}

.wrapper-tabs {
  padding-top: 20px;
  padding-bottom: 12px;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: pre;
}

.wrapper-tabs::-webkit-scrollbar {
  width: 4px;
  background: inherit;
}

.wrapper-tabs::-webkit-scrollbar-thumb {
  border-width: 1px 1px 1px 2px;
  border-color: #777;
  background-color: #aaa;
}

.wrapper-tabs::-webkit-scrollbar-thumb:hover {
  border-width: 1px 1px 1px 2px;
  border-color: #555;
  background-color: #777;
}

.wrapper-tabs::-webkit-scrollbar-track {
  border-width: 0;
}
.wrapper-tabs::-webkit-scrollbar-track:hover {
  border-left: solid 1px #aaa;
  background-color: #eee;
}
/*.wrapper-tabs::-webkit-scrollbar {
  width: 0;
  height: 0;
} */

.wrapper-tabs-view {
  padding-left: 0px;
  text-align: left;
}

.wrapper-tabs-view a {
  font-size: 16px;
  padding-left: 0px;
  padding-right: 0px;
  text-decoration: none;
  margin-right: 15px;
}
.wrapper-tabs-view a:hover {
  color: #2980b9;
}

.wrapper-tabs-view-success {
  color: #3498db;
}

.wrapper-tabs-view-error {
  color: #e74c3c;
}

.wrapper-tabs-view-a {
}

.wrapper-tabs-view-a-select {
  text-decoration: underline;
  padding-bottom: 10px;
  border-bottom: 5px solid #3498db;
}
.wrapper-tabs-view-a-select-error {
  text-decoration: underline;
  padding-bottom: 10px;
  border-bottom: 5px solid #e74c3c;
}

.wrapper_input_item {
  width: 100%;
  border-radius: 4px;
  padding: 5px 0 5px 0;

  margin-top: 5px;
  margin-bottom: 15px;
  background-color: white;
}

.wrapper_input_item_success {
  border: 1px solid #b1b9c3;
}

.wrapper_input_item_error {
  border: 1px solid #e74c3c;
}

.wrapper_input_item .wrapper_input_item_view {
  display: flex;
  flex-direction: row;
  padding: 6px 10px 6px 10px;
}

.wrapper_input_item .input_item_body {
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
}

.wrapper_input_item .input_item_click {
  width: 20%;
  min-width: 100px;
  padding: 0 5px 0 5px;
  text-align: center;
  font-size: 16px;
}

.wrapper_input_item .input_item_click a {
  text-decoration: none;
  color: #3498db;
}

.wrapper_input_item .input_item_click a {
  text-decoration: none;
  color: #2980b9;
}

.input_item_city {
  height: 250px;
  overflow: scroll;
}

.input_item_city_a {
  font-size: 16px;
  color: #3498db;
  text-decoration: none;
}
.input_item_city_a:hover {
  color: #2980b9;
}

.input_item_city {
  height: 250px;
  overflow: scroll;
}

.input_item_city_a {
  font-size: 16px;
  color: #3498db;
  text-decoration: none;
}
.input_item_city_a:hover {
  color: #2980b9;
}

.input_item_city_a_sphera {
  background-color: #fffaf0;
  padding: 5px 10px 5px 10px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
}
.input_item_city_a_sphera_loader {
  text-align: right;
  width: 20px;
  margin-left: 10px;
}
.input_item_city_a_view {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.input_item_city_a_icon {
  padding-top: 2px;
  width: 25px;
  height: 20px;
  text-align: center;
}

.input_item_city_preview {
  background-color: #fffaf0;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
}
.input_item_city_preview span {
  text-align: right;
}
.input_item_city_preview_title {
  text-align: right;
  border-bottom: 1px solid #dcdcdc;
  padding: 5px 10px 5px 10px;
}
.input_item_city_a_text {
  margin-top: 3px;
  padding-top: 5px;
  padding-left: 5px;
}

.input_item_city_a_view_selected {
  padding: 5px 10px 10px 10px;
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 18px;
  padding-bottom: 5px;
  -webkit-user-select: none;
  user-select: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 27px;
  width: 27px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
/* .container:hover input ~ .checkmark {
  background-color: #ccc;
}
 */

.container_default:hover input ~ .checkmark {
  background-color: #ccc;
}
.container_warning:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
/*.container input:checked ~ .checkmark {
  background-color: #2196f3;
}*/

.container_default input:checked ~ .checkmark {
  background-color: #2196f3;
}
.container_warning input:checked ~ .checkmark {
  background-color: #e74c3c;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Customize the label (the container) */
.container-radio-button {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  user-select: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Hide the browser's default radio button */
.container-radio-button input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark-radio {
  position: absolute;
  top: 2px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-radio-button:hover input ~ .checkmark-radio {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-radio-button input:checked ~ .checkmark-radio {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark-radio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio-button input:checked ~ .checkmark-radio:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-radio-button .checkmark-radio:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.wrapper-main-bottom {
  position: fixed;
  padding: 5px 10px 5px 10px;
  width: 100%;
  bottom: 0;
  background-color: #f8f8ff;
  border-top: 1px solid #dcdcdc;
  text-align: center;
  z-index: 10;
}

.wrapper-main-bottom-view {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
}

.select_items_content {
  display: inline-block;
  text-align: left;
}
.select_items_wrapper {
  border-radius: 8px;
  text-align: left;
  z-index: 999999994;
}

.select_items_wrapper_success {
  border: 1px solid #b1b9c3;
}
.select_items_wrapper_error {
  border: 1px solid #e74c3c;
}
.select_items_wrapper_view {
  display: flex;
}

.select_items_wrapper_a {
  text-decoration: none;
  color: #4f4f4f;
}

.select_items_wrapper .select_items_wrapper_view .body {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  padding: 6px 5px 5px 10px;
}
.select_items_wrapper .select_items_wrapper_view .icon {
  width: 30px;
  padding: 7px 5px 0 5px;
}

.select_items_wrapper .select_sub_items_wrapper {
  position: absolute;
  margin-top: -3px;
  margin-left: -1px;
  overflow-x: auto;
  max-height: 200px;
  background-color: #fff;
  box-shadow: 0px 5px 7px -1px rgba(0, 0, 0, 0.38);

  border: 1px solid #dcdcdc;
  z-index: 999999996;
}
.select_items_wrapper .select_sub_items_right {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.select_items_wrapper .select_sub_items_left {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.select_items_wrapper .select_sub_items_wrapper .selected {
  padding: 10px 5px 10px 5px;
  font-size: 16px;
}

.select_items_wrapper .select_sub_items_wrapper .top_border {
  border-top: 1px solid #dcdcdc;
}
.select_sub_items_wrapper_a {
  color: #696969;
  text-decoration: none;
}
.select_sub_items_wrapper_a:hover {
  color: #000000;
}

.selected:hover {
  background-color: #fffaf0;
}

/* Text */

.select_items_wrapper input {
  width: 100%;

  font-size: 16px;
  padding: 10px 0 10px 10px;
  border: 0;
  border-bottom: 1px solid silver;
  outline: none;
}

.select_items_wrapper .loader {
  margin: 10px 0 10px 0;
}

.select_items_wrapper .notSearching {
  margin: 5px auto;
  text-align: center;
  color: #b3b1b1;
}
.sub-title {
  font-size: 12px;
  color: #828282;
}

.select-items-clear-view {
  display: flex;
  justify-content: flex-end;
  padding: 5px 10px 5px 10px;
}
.select-items-clear-all {
  color: #2980b9;
  text-align: center;
}
.select-items-clear-all:hover {
  color: #194563;
}

.info_alert_wrapper {
  position: fixed;
  right: 25px;
  top: 100px;
  width: 8%;
  min-width: 300px;
  max-width: 500px;
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  box-shadow: -10px 17px 13px -11px rgba(0, 0, 0, 0.75);
  font-size: 16px;
  z-index: 999999999;
}

.info_alert_wrapper_success {
  background-color: #2ecc71;
  border: 1px solid #2ecc71;
  color: #fff;
}

.info_alert_wrapper_error {
  background-color: #8f1919;
  border: 1px solid #8f1919;
  color: #fff;
}

.info_alert_wrapper_view {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.info_alert_wrapper_view_icon {
  padding: 2px 5px 0 5px;
}

.info_alert_wrapper_view_description {
  padding: 2px 5px 5px 5px;
}

.content_component {
  width: 100%;
  padding-top: 20px;
}

.content_center {
  margin: 0 auto;
}

.wrapper_default {
  padding: 10px;
  max-width: 900px;
  min-width: 300px;
}

.label_wrapper {
  width: 100%;
}
.label_wrapper_view {
  display: flex;
  align-items: flex-start;

  padding: 10px 5px 10px 0;
}

.label_wrapper .title {
  min-width: 160px;
  font-size: 14px;
  color: #696969;
}
.label_wrapper .value {
  font-size: 14px;
  margin-left: 15px;
  color: #363636;
}

.label_wrapper .label_link {
  color: #3498db;
}

.star-wrapper {
  width: 200px;
}
.star-wrapper-view {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.star-wrapper-view-text {
  margin-left: 10px;
  font-size: 16px;
}

.images-preview-cards {
  display: flex;
  flex-direction: row;
}

.images-preview-cards .image-remove {
  position: absolute;
  background-color: #ffffff;
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  z-index: 9992;
}

.images-preview-card {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
  margin-right: 20px;
  cursor: pointer;
}

.images-preview-card .images-preview-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.images-preview-cards-full-screen {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: black;
  z-index: 9991;
}

.images-preview-cards-full-screen .close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 9992;
}

.images-preview-cards-full-screen .left-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  left: 0;
  top: 100px;
  bottom: 100px;
  z-index: 9992;
}

.images-preview-cards-full-screen .right-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  right: 0;
  top: 100px;
  bottom: 100px;
  z-index: 9992;
}

.images-preview-cards-full-screen .preview-images {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.images-preview-cards-full-screen .preview-images .preview-images-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.images-preview-cards-full-screen .pages {
  position: absolute;
  left: 0;
  bottom: 0;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  border-top-right-radius: 10px;
  background-color: #ffffff;
}

.info-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.info-content-view-warning {
  background-color: bisque;
  padding: 10px 15px 10px 15px;
  border-radius: 10px;
  color: rgb(156, 100, 32);
}

.list-item-wrapper {
  display: flex;
  padding: 10px;
  border: 1px solid silver;
  border-radius: 2px;
  margin: 10px 0 10px 0;
  flex-direction: row;
  justify-content: space-between;
}

.list-item-wrapper a {
  color: #e74c3c;
}

.list-item-wrapper a:hover {
  color: #c0392b;
  text-decoration: none;
}

.empty_main {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  text-align: center;
  color: #696969;
}

.modal_alert_full_screen {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999;
}
.modal_alert_full_screen .wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  border: 0px solid black;
}

.modal_alert_full_screen .close {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

.modal_alert_full_screen .close:hover {
  opacity: 0.8;
}

.modal_alert_full_screen .title {
  display: flex;
  border: 0px solid black;
  font-weight: bold;
  margin-right: 60px;
  height: 45px;
  font-size: 20px;
  padding-left: 20px;
  padding-bottom: 5px;
  justify-content: flex-start;
  align-items: center;
}

.modal_alert_full_screen .description {
  display: flex;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: silver;
  padding: 20px;
  flex: 1 1;
  overflow: auto;
}

@font-face {
  font-family: "Source Sans Pro Extra";
  src: url(/static/media/SourceSansProExtraLight.fd04b68b.ttf) format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  src: url(/static/media/SourceSansProLight.ee2a11b8.ttf) format("truetype");
}

@font-face {
  font-family: "Source Sans Pro Regular";
  src: url(/static/media/SourceSansProRegular.5182da42.ttf) format("truetype");
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Source Sans Pro Regular";
  background-color: #fff;
  font-size: 14px;
  line-height: 1.5;
}

h1,
h2,
h3 {
  font-family: "Source Sans Pro Regular";
}

.link-button {
  border: none;
  cursor: pointer;
  text-decoration: underline;
  outline: none;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.wrapper-main {
}

.wrapper-main-page {
  width: 400px;
  margin: 10px auto;
}

.wrapper-main-page h1 {
  text-align: center;
}

.main_preview {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 100px;
}
.main_preview .organization_title {
  display: flex;
  width: 100%;
}
.main_preview_null_organizations {
  text-align: center;
  margin-top: 30px;
}
.main_preview .organization {
  display: flex;
  overflow: hidden;
  padding: 5px;
  margin: 5px;
  width: 200px;
  padding: 10px;
  height: 100px;
  border: 0.5px solid rgba(148, 142, 148, 0.5);
  border-radius: 8px;
  cursor: default;
}

.main_preview .organization:hover {
  border: 0.5px solid transparent;
  border-radius: 6px;
  box-shadow: 0px 2px 14px -3px rgba(148, 142, 148, 1);
}
.main_preview .organization_cell {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.main_preview .organization_body {
  /* padding: 5px 5px 5px 5px;
  border: 1px solid transparent; */
}

.organization_body:hover {
  cursor: pointer;
}
.organization_body .title {
  font-size: 14px;
  font-weight: bold;
  color: #363636;
}
.organization_body .address {
  font-size: 12px;
  font-weight: normal;
  color: #828282;
}

.organization_body .active {
  display: flex;
  font-size: 12px;
}

.main_preview .organization_setting {
  padding: 10px;
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_preview .organization_setting {
}
.organization_cell .a0 {
  color: #1abc9c;
}
.organization_cell .a1 {
  color: #2ecc71;
}
.organization_cell .a2 {
  color: #e74c3c;
}

.organization_cell_left {
  display: flex;
  flex: 2 1;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-flow: row nowrap;
}

.organization_cell_left_logotype {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #dcdcdc;
  background-color: white;
  margin-right: 10px;
  margin-top: 5px;
  object-fit: cover;
}

.main_alert_no_user_title {
  font-size: 16px;
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}

.new_organization_view {
  margin: 20px auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  max-width: 500px;
  min-width: 300px;
}

.new_organization_view_phone_null {
  text-align: center;
  margin-bottom: 20px;
}

.new_organization_view_phone_button {
  width: 150px;
  padding-top: 20px;
  margin-top: 20px;
  margin: auto;
}
/*
Table
*/
.new_organization_view_table {
  margin: 20px auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  max-width: 700px;
  min-width: 500px;
}

.form_operating_mode_table {
  display: table;
  width: 100%;
}

.form_operating_mode_table_cell {
  display: table-cell;
  padding-top: 10px;
  padding-bottom: 10px;
}

.new_organization_view_table .header {
  background-color: #fffaf0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid #dcdcdc;
  text-align: center;
}

.form_operating_mode_table .day {
  width: 30%;
  font-size: 16px;
  padding-left: 5px;
  padding-right: 5px;
}

.form_operating_mode_table .timeParametr {
  width: 30%;
  font-size: 16px;
  padding-left: 5px;
  padding-right: 5px;
}

.form_operating_mode_table .border_left {
  border-left: 1px solid #dcdcdc;
}

.form_operating_mode_table .border_right {
  border-right: 1px solid #dcdcdc;
}
.form_operating_mode_table .border_bottom {
  border-bottom: 1px solid #dcdcdc;
}

.form_operating_mode_table .timeDay {
  width: 20%;
  padding-left: 5px;
  padding-right: 5px;
}

.form_operating_mode_table_cell .work_day {
  font-size: 12px;
}

.new_organization_view_table .vih {
  background-color: #fff5ee;
}

.files_hidden {
  display: none;
}
.new_organization_view_all_images {
  margin-top: 20px;
  width: 100%;
}
.new_organization_view_all_images_count_text {
  margin-top: 20px;
}
.new_organization_view_images {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 5px 0 5px;
  width: 150px;
  height: 150px;
  text-align: center;
  float: left;
}
.new_organization_view_images .new_organization_view_images_remove {
  position: absolute;
  background-color: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  top: 5px;
  left: 20px;
}
.dostpr_img {
  width: 80%;
  height: 80%;
  border-radius: 10%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 25px;
  object-fit: cover;
}

.new_organization_view_images_vertical {
  position: relative;
  margin: 5px 5px 0 5px;
  width: 250px;
  height: 350px;
  text-align: center;
  float: left;
}

.dostpr_img_vertical {
  width: 80%;
  height: 80%;
  border-radius: 10%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 25px;
  object-fit: cover;
}
.new_organization_view_images_title {
  position: absolute;
  bottom: 25px;
  left: 0;
  border: 1px solid black;
  z-index: 5;
}

.dostpr_img_logotype {
  border: 2px solid #f39c12;
}
.dostpr_img_no_logotype {
  border: 2px solid white;
}

.new_organization_view_images_logo {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0px;
}
.new_organization_view_images_logo a {
  text-decoration: none;
  color: #3498db;
}

.new_organization_buttons {
  width: 200px;
  margin: auto;
  text-align: center;
}

.new-organization-phone-add {
  background-color: antiquewhite;
  border: 1px solid silver;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.main_preview {
  margin-top: 20px;
  margin-bottom: 100px;
}
.news_modal_view_margin_top {
  margin-top: 10px;
  text-align: right;
}
.news_modal_view_margin_top_view {
  display: inline-block;
}

.news_modal_preview_video {
  position: relative;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow: hidden;
  margin-bottom: 20px;
}
.news_modal_preview_video_size {
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 10px;
}

.news_modal_preview_video_close {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 9999;
}
.news_line {
  margin-top: 20px;
}

.organization_content {
  margin: auto;
  width: 90%;
  min-width: 300px;
}
.organization_wrapper {
  position: relative;
  display: inline-block;
  margin: 20px auto;
  margin-bottom: 40px;
  width: 100%;
  min-width: 300px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fffaf0;
  border: 1px solid #e6e6fa;
  border-radius: 6px;
}

.organization_wrapper a {
  font-size: 18px;
  color: #3498db;
}

.organization_wrapper a:hover {
  color: #2980b9;
}

.organization_required {
  position: relative;
  width: 44%;
  margin-top: 10px;
  float: left;
  margin-left: 0.5%;
  margin-right: 0.5%;
  text-align: left;
  padding: 2%;
  min-height: 250px;
  margin-bottom: 10px;
}
.organization_required .borderTitle {
  font-size: 16px;
  padding-top: 10px;
}
.emptyOrganizationRequired {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.8;
  border-radius: 10px;
  z-index: 0;
}
.emptyOrganizationRequiredText {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  z-index: 1;
}
.organization_required a {
  margin-top: 10px;
}
.organization_wrapper_title {
  font-size: 22px;
  color: #4f4f4f;
  margin-bottom: 10px;
}
.organization_connect_message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 10px 0 0 0;
}
.organization_connect_message_view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 700px;
  min-width: 400px;
  border-width: 1px;
  border-color: #eb4d4b;
  border-style: solid;
  border-radius: 4px;
  background-color: #ff7979;
  padding: 10px;
}

.organization_connect_message_view .body {
  color: #ffffff;
  padding-right: 5px;
}

.organization_connect_message_view .connect_btn a {
  background-color: #ffffff;
  color: #ff7979;
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  text-decoration: none;
}

.main_preview {
  margin: 25px auto;
  width: 800px;
  padding-bottom: 100px;
}
.main_preview_bottom {
  clear: both;
}

.main_preview .poster_view {
  position: relative;
  width: 250px;
  height: 300px;
  float: left;
  margin: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  z-index: 0;
}

.main_preview .poster_view_img {
  width: 100%;
  object-fit: cover;
}
.poster_view_title_view {
  position: absolute;
  bottom: 0;
  height: 35px;
  left: 0;
  right: 0;
  padding: 0 10px 0 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 2;
  color: #fff;
}
.poster_view_title_overlay {
  position: absolute;
  bottom: 0;
  height: 49px;
  left: 0;
  right: 0;
  opacity: 0.5;
  background-color: #000000;

  z-index: 1;
}

.poster_view_images_remove {
  position: absolute;
  right: 5px;
  width: 35px;
  height: 35px;
  top: 5px;
  background-color: #fff;
  border-radius: 50%;

  border: 1px solid red;
}

.poster_view_images_editor {
  position: absolute;
  right: 55px;
  width: 35px;
  height: 35px;
  top: 5px;
  background-color: #fff;
  border-radius: 50%;

  border: 1px solid #8b8989;
}

.main_preview_null {
  margin: 20px auto;
  text-align: center;
  font-size: 18px;
  color: silver;
}

.poster_modal_view_margin_top {
  margin-top: 15px;
}

.poster_modal_view_time_to_date_buttons {
  width: 200px;
  margin: 20px auto;
}

.poster_modal_view_time_to_date_null {
  color: silver;
  text-align: center;
}

.poster_view_images {
  position: relative;
  margin: auto;
  width: 250px;
  text-align: center;
}

.poster_view_images .poster_view_images_remove {
  position: absolute;
  background-color: white;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  top: 15px;
  left: 15px;
}

.poster_img {
  width: 100%;
  height: 100%;
  border-radius: 10%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 25px;
  object-fit: cover;
}

.profile-wrapper {
    margin: 40px auto;
    width: 15%;
    max-width: 800px;
    min-width: 300px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
.required_water_reading_wrapper {
  margin: 20px auto;
  padding: 0 10px 0 10px;
}

.required_water_reading_table {
  display: table;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  margin-top: 2px;
  width: 100%;
}

.required_water_reading_table_right_border {
  border-right: 1px solid #dcdcdc;
}

.required_water_reading_table_new_required {
  background-color: #e6e6fa;
}

.required_water_reading_table_cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 5px 5px 5px 5px;
}

.required_water_reading_table .header {
  font-size: 16px;
  font-weight: bold;
}

.required_water_reading_table .body {
  font-size: 16px;
}

.required_water_reading_table .number_ {
  width: 10%;
  min-width: 70px;
}

.required_water_reading_table .date {
  width: 10%;
  min-width: 100px;
}

.required_water_reading_table .name {
  width: 40%;
  min-width: 150px;
}

.required_water_reading_table .number_object {
  width: 40%;
  min-width: 150px;
}
.required_water_reading_wrapper .click:hover {
  background-color: #faebd7;
  cursor: pointer;
}

.required_water_reading_loading {
  margin: 5% auto;
}

.required_water_reading_error {
  margin: 25px auto;
  color: #e74c3c;
  font-size: 22px;
  text-align: center;
}

.required_water_reading_first_number_object {
  margin-top: 2em;
  margin-bottom: 2em;
}
.required_water_reading_first_number_object_flex {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.required_water_reading_first_number_object_flex_view {
  width: 200px;
}

.street_header_sub_menu_view {
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 95%;
  right: 0px;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-right: 10px;
  text-align: right;
}

.street_alert_sub_menu_view {
  margin-bottom: 10px;
}

.street_wrapper_view {
  padding: 10px;
  padding-bottom: 200px;
}

.street_wrapper_view_list_item {
  display: table;
  width: 100%;
  border-bottom: 1px solid #9c9c9c;
}
.street_wrapper_view_list_item .street_wrapper_view_list_item_cell {
  display: table-cell;
}

.street_wrapper_view_list_item
  .street_wrapper_view_list_item_cell_title_street {
  width: 40%;
  font-size: 18px;
  padding-top: 8px;
  padding-bottom: 10px;
}

.street_wrapper_view_list_item .street_wrapper_view_list_item_cell_title_city {
  width: 40%;
  font-size: 18px;
  padding-top: 8px;
  padding-bottom: 10px;
}

.street_wrapper_view_list_item
  .street_wrapper_view_list_item_cell_title_editor {
  width: 10%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.street_wrapper_view_list_item
  .street_wrapper_view_list_item_cell_title_remove {
  width: 10%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.removeStreet {
  font-weight: bold;
}
.street_wrapper_view_next_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px auto;
}

