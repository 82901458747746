.form-input {
  width: 100%;
  border-radius: 4px;
  padding: 5px 0 5px 0;
  /*border: 1px solid #b1b9c3;*/
  margin-top: 15px;
  background-color: white;
}

.form-input input {
  width: 90%;
  font-size: 16px;
  padding: 6px 0 6px 10px;
  border: 0;
  outline: none;
}

.form-input span {
  padding: 6px 0 6px 10px;
  color: rgb(133, 131, 131);
}

.success-input {
  border: 1px solid #b1b9c3;
}

.error-input {
  border: 1px solid red;
}

.error-text-input {
  color: red;
  text-align: left;
}
.date_picker_component_view {
  border-radius: 4px;
  border: 1px solid #b1b9c3;
}
.dateToTime_wrapper {
  padding: 10px 10px 0 10px;
  border: 1px solid silver;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #faebd7;
  z-index: 0;
}
.date_picker_component {
  width: 90%;
  font-size: 16px;
  padding: 6px 0 6px 10px;
  border: 0;
  outline: none;
}

.dateToTime_wrapper_datetime_view {
  position: relative;
}
.dateToTime_wrapper_datetime_remove {
  position: absolute;
  right: 10px;
  top: 0px;
  cursor: pointer;
}
