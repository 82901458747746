.images-preview-cards {
  display: flex;
  flex-direction: row;
}

.images-preview-cards .image-remove {
  position: absolute;
  background-color: #ffffff;
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  z-index: 9992;
}

.images-preview-card {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
  margin-right: 20px;
  cursor: pointer;
}

.images-preview-card .images-preview-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.images-preview-cards-full-screen {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: black;
  z-index: 9991;
}

.images-preview-cards-full-screen .close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 9992;
}

.images-preview-cards-full-screen .left-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  left: 0;
  top: 100px;
  bottom: 100px;
  z-index: 9992;
}

.images-preview-cards-full-screen .right-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  right: 0;
  top: 100px;
  bottom: 100px;
  z-index: 9992;
}

.images-preview-cards-full-screen .preview-images {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.images-preview-cards-full-screen .preview-images .preview-images-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.images-preview-cards-full-screen .pages {
  position: absolute;
  left: 0;
  bottom: 0;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  border-top-right-radius: 10px;
  background-color: #ffffff;
}
