.required_water_reading_wrapper {
  margin: 20px auto;
  padding: 0 10px 0 10px;
}

.required_water_reading_table {
  display: table;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  margin-top: 2px;
  width: 100%;
}

.required_water_reading_table_right_border {
  border-right: 1px solid #dcdcdc;
}

.required_water_reading_table_new_required {
  background-color: #e6e6fa;
}

.required_water_reading_table_cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 5px 5px 5px 5px;
}

.required_water_reading_table .header {
  font-size: 16px;
  font-weight: bold;
}

.required_water_reading_table .body {
  font-size: 16px;
}

.required_water_reading_table .number_ {
  width: 10%;
  min-width: 70px;
}

.required_water_reading_table .date {
  width: 10%;
  min-width: 100px;
}

.required_water_reading_table .name {
  width: 40%;
  min-width: 150px;
}

.required_water_reading_table .number_object {
  width: 40%;
  min-width: 150px;
}
.required_water_reading_wrapper .click:hover {
  background-color: #faebd7;
  cursor: pointer;
}

.required_water_reading_loading {
  margin: 5% auto;
}

.required_water_reading_error {
  margin: 25px auto;
  color: #e74c3c;
  font-size: 22px;
  text-align: center;
}

.required_water_reading_first_number_object {
  margin-top: 2em;
  margin-bottom: 2em;
}
.required_water_reading_first_number_object_flex {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.required_water_reading_first_number_object_flex_view {
  width: 200px;
}
