.wrapper-main-bottom {
  position: fixed;
  padding: 5px 10px 5px 10px;
  width: 100%;
  bottom: 0;
  background-color: #f8f8ff;
  border-top: 1px solid #dcdcdc;
  text-align: center;
  z-index: 10;
}

.wrapper-main-bottom-view {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
}
