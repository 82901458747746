.modal_alert_full_screen {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999;
}
.modal_alert_full_screen .wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 0px solid black;
}

.modal_alert_full_screen .close {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

.modal_alert_full_screen .close:hover {
  opacity: 0.8;
}

.modal_alert_full_screen .title {
  display: flex;
  border: 0px solid black;
  font-weight: bold;
  margin-right: 60px;
  height: 45px;
  font-size: 20px;
  padding-left: 20px;
  padding-bottom: 5px;
  justify-content: flex-start;
  align-items: center;
}

.modal_alert_full_screen .description {
  display: flex;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: silver;
  padding: 20px;
  flex: 1;
  overflow: auto;
}
