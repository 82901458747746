.label_wrapper {
  width: 100%;
}
.label_wrapper_view {
  display: flex;
  align-items: flex-start;

  padding: 10px 5px 10px 0;
}

.label_wrapper .title {
  min-width: 160px;
  font-size: 14px;
  color: #696969;
}
.label_wrapper .value {
  font-size: 14px;
  margin-left: 15px;
  color: #363636;
}

.label_wrapper .label_link {
  color: #3498db;
}
