.btn-big {
  width: 100%;
  max-width: 100%;
  font-size: 1.25rem;
  padding: 15px 5px 15px 5px;
  border-radius: 4px;
}

.btn-block {
}

.btn-big-primary {
  color: white;
  border: 1px solid #51af33;
  background-color: #51af33;
}
