.formInput {
  display: flex;
  align-items: center;
  padding-right: 0;
  flex-direction: row;
}
.formInput .rightIcon {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
}
.formInput input {
  /* width: 90%; */
  flex: 1;
  font-size: 16px;
  padding: 6px 0 6px 10px;
  border: 0px solid black;
  outline: none;
}
.formInput textarea {
  /* width: 90%; */
  flex: 1;
  font-size: 16px;
  padding: 6px 0 6px 10px;
  border: 0;
  outline: none;
}

.formInput_view {
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  padding-right: 5px;
}

.formInput_icon {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  opacity: 0.5;
}
.formInput_icon_remove {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
}
.formInput_input {
  width: 100%;
}

.formInputError {
  position: absolute;
  border: 0px solid black;
  font-size: 12px;
  width: 100%;
  bottom: -18px;
  left: 0;
  color: red;
}

.formInput_color {
  position: relative;
}

.formInput_color_code {
  position: absolute;
  right: 5px;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  top: 0;
}

.formInput_top_title {
  display: table;
  clear: both;
}

.formInput_color_modal_color {
  position: absolute;
  z-index: 5;
}

.formInput_color_modal_color_btn {
  display: flex;
  background-color: #fff;
  justify-content: space-around;
  border-width: 1px;
  border-style: solid;
  border-color: silver;
  margin-top: -5px;
  padding: 5px 5px 5px 5px;
}

.formInput_color_modal_color_btn a {
  text-decoration: none;
  color: #3498db;
}

.formInput_view_select {
  cursor: pointer;
}
.formInput_select {
  color: #3498db;
  font-size: 16px;
  text-decoration: underline;
  padding: 3px 5px 3px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.input_emoji {
  /* position: absolute; */
  margin-top: -10px;
  /* z-index: 45; */
}
.form-control-phone {
  font-size: 17px;
  margin-left: 10px;
}
