.info_alert_wrapper {
  position: fixed;
  right: 25px;
  top: 100px;
  width: 8%;
  min-width: 300px;
  max-width: 500px;
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  -webkit-box-shadow: -10px 17px 13px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -10px 17px 13px -11px rgba(0, 0, 0, 0.75);
  box-shadow: -10px 17px 13px -11px rgba(0, 0, 0, 0.75);
  font-size: 16px;
  z-index: 999999999;
}

.info_alert_wrapper_success {
  background-color: #2ecc71;
  border: 1px solid #2ecc71;
  color: #fff;
}

.info_alert_wrapper_error {
  background-color: #8f1919;
  border: 1px solid #8f1919;
  color: #fff;
}

.info_alert_wrapper_view {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.info_alert_wrapper_view_icon {
  padding: 2px 5px 0 5px;
}

.info_alert_wrapper_view_description {
  padding: 2px 5px 5px 5px;
}
