.main_preview {
  margin: 25px auto;
  width: 800px;
  padding-bottom: 100px;
}
.main_preview_bottom {
  clear: both;
}

.main_preview .poster_view {
  position: relative;
  width: 250px;
  height: 300px;
  float: left;
  margin: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  z-index: 0;
}

.main_preview .poster_view_img {
  width: 100%;
  object-fit: cover;
}
.poster_view_title_view {
  position: absolute;
  bottom: 0;
  height: 35px;
  left: 0;
  right: 0;
  padding: 0 10px 0 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 2;
  color: #fff;
}
.poster_view_title_overlay {
  position: absolute;
  bottom: 0;
  height: 49px;
  left: 0;
  right: 0;
  opacity: 0.5;
  background-color: #000000;

  z-index: 1;
}

.poster_view_images_remove {
  position: absolute;
  right: 5px;
  width: 35px;
  height: 35px;
  top: 5px;
  background-color: #fff;
  border-radius: 50%;

  border: 1px solid red;
}

.poster_view_images_editor {
  position: absolute;
  right: 55px;
  width: 35px;
  height: 35px;
  top: 5px;
  background-color: #fff;
  border-radius: 50%;

  border: 1px solid #8b8989;
}

.main_preview_null {
  margin: 20px auto;
  text-align: center;
  font-size: 18px;
  color: silver;
}
