@font-face {
  font-family: "Source Sans Pro Extra";
  src: url("../../fonts/SourceSansProExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/SourceSansProLight.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro Regular";
  src: url("../../fonts/SourceSansProRegular.ttf") format("truetype");
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Source Sans Pro Regular";
  background-color: #fff;
  font-size: 14px;
  line-height: 1.5;
}

h1,
h2,
h3 {
  font-family: "Source Sans Pro Regular";
}

.link-button {
  border: none;
  cursor: pointer;
  text-decoration: underline;
  outline: none;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}
