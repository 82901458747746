.wrapper_input_item {
  width: 100%;
  border-radius: 4px;
  padding: 5px 0 5px 0;

  margin-top: 5px;
  margin-bottom: 15px;
  background-color: white;
}

.wrapper_input_item_success {
  border: 1px solid #b1b9c3;
}

.wrapper_input_item_error {
  border: 1px solid #e74c3c;
}

.wrapper_input_item .wrapper_input_item_view {
  display: flex;
  flex-direction: row;
  padding: 6px 10px 6px 10px;
}

.wrapper_input_item .input_item_body {
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
}

.wrapper_input_item .input_item_click {
  width: 20%;
  min-width: 100px;
  padding: 0 5px 0 5px;
  text-align: center;
  font-size: 16px;
}

.wrapper_input_item .input_item_click a {
  text-decoration: none;
  color: #3498db;
}

.wrapper_input_item .input_item_click a {
  text-decoration: none;
  color: #2980b9;
}
